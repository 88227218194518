'use client';
import type { Placement } from '@floating-ui/react';
import clsx from 'clsx';
import Link, { type LinkProps } from 'next/link';
import React, { type ReactNode } from 'react';
import type { InputSize } from '../Input/types';
import { Tooltip, type TooltipLabel } from '../Tooltip';
import styles from './Button.module.scss';

export type ButtonType = 'primary' | 'dangerous' | 'submit' | 'submitPrimary' | 'text' | 'largeCTA';
export type BorderType = 'solid' | 'dashed';

interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  icon?: JSX.Element;
  rightIcon?: JSX.Element;
  type?: ButtonType;
  tooltip?: TooltipLabel;
  tooltipPlacement?: Placement;
  size?: InputSize;
  hoverable?: boolean;
  borderType?: BorderType;
  animating?: boolean;
}

interface LinkButtonProps extends Omit<LinkProps, 'size'> {
  icon?: JSX.Element;
  rightIcon?: JSX.Element;
  type?: ButtonType;
  className?: string;
  children?: ReactNode;
  target?: string;
  tooltip?: TooltipLabel;
  size?: InputSize;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      icon,
      rightIcon,
      children,
      type,
      className,
      tooltip,
      onClick,
      disabled,
      size,
      hoverable,
      tooltipPlacement,
      animating,
      borderType = 'solid',
      ...restProps
    },
    ref,
  ) => {
    const isSubmitButton = type === 'submit' || type === 'submitPrimary';
    const classList = clsx(
      styles.button,
      size == 'small' ? styles.small : undefined,
      type ? styles[type] : undefined,
      borderType ? styles[borderType] : undefined,
      type === 'submitPrimary' ? styles.primary : undefined,
      (onClick ?? isSubmitButton ?? hoverable) && !disabled ? styles.hoverable : styles.disabled,
      animating ? styles.animating : undefined,
      className,
    );

    if (tooltip) {
      return (
        <Tooltip
          label={tooltip}
          background={type === 'dangerous' ? 'hsl(0, 67%, 45%)' : undefined}
          placement={tooltipPlacement}
          autoPlace={tooltipPlacement != null ? false : true}
        >
          <button
            className={classList}
            onClick={onClick}
            type={isSubmitButton ? 'submit' : 'button'}
            ref={ref}
            disabled={disabled}
            {...restProps}
          >
            {icon != null && <div className={styles.icon}>{icon}</div>}
            {children != null && <span>{children}</span>}
            {rightIcon != null && <div className={styles.icon}>{rightIcon}</div>}
          </button>
        </Tooltip>
      );
    }

    return (
      <button
        className={classList}
        onClick={onClick}
        type={isSubmitButton ? 'submit' : 'button'}
        ref={ref}
        disabled={disabled}
        {...restProps}
      >
        {icon != null && <div className={styles.icon}>{icon}</div>}
        {children != null && <span>{children}</span>}
        {rightIcon != null && <div className={styles.icon}>{rightIcon}</div>}
      </button>
    );
  },
);
Button.displayName = 'Button';

export const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(
  ({ icon, children, type, className, href, target, size, tooltip, ...props }, ref) => {
    const classList = clsx(
      styles.button,
      size == 'small' ? styles.small : undefined,
      type ? styles[type] : undefined,
      styles.hoverable,
      className,
    );

    if (tooltip) {
      return (
        <Tooltip label={tooltip}>
          <Link className={classList} href={href} target={target} ref={ref} {...props}>
            {icon != null && <div className={styles.icon}>{icon}</div>}
            {children != null && <span>{children}</span>}
          </Link>
        </Tooltip>
      );
    }

    return (
      <Link className={classList} href={href} target={target} ref={ref} {...props}>
        {icon != null && <div className={styles.icon}>{icon}</div>}
        {children != null && <span>{children}</span>}
      </Link>
    );
  },
);
LinkButton.displayName = 'LinkButton';
